import React from 'react';
import { theme, textColor } from '../../constants/theme';
import setHighlightColor from '../../utils/setHighlightColor';

type Props = {
  highlightOnHover?: boolean,
};

const MethodsAndDocumentationIcon = ({ highlightOnHover }: Props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 14.9998C30 14.3785 29.4674 13.8459 28.8461 13.8459C28.2248 13.8459 27.6923 14.3785 27.6923 14.9998C27.6923 15.6211 28.2248 16.1536 28.8461 16.1536C29.5562 16.1536 30 15.6211 30 14.9998Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M25.6511 25.6507C26.0948 25.2069 26.0948 24.4968 25.6511 24.0531C25.2073 23.6093 24.4972 23.6093 24.0534 24.0531C23.6096 24.4968 23.6096 25.2069 24.0534 25.6507C24.4972 26.0945 25.2073 26.0945 25.6511 25.6507Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M15.0001 -0.000244141C14.3788 -0.000244141 13.8463 0.5323 13.8463 1.1536C13.8463 1.7749 14.3788 2.30745 15.0001 2.30745C15.6214 2.30745 16.1539 1.7749 16.1539 1.1536C16.1539 0.5323 15.6214 -0.000244141 15.0001 -0.000244141Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M4.43803 4.43763C3.99425 4.79266 3.99425 5.59148 4.43803 5.94651C4.88182 6.3903 5.59188 6.3903 6.03567 5.94651C6.47945 5.50272 6.47945 4.79266 6.03567 4.34888C5.59188 3.99385 4.79306 3.99385 4.43803 4.43763Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M6.10352e-05 14.9998C6.10352e-05 15.6211 0.532606 16.1536 1.15391 16.1536C1.77521 16.1536 2.30775 15.6211 2.30775 14.9998C2.30775 14.3785 1.77521 13.8459 1.15391 13.8459C0.532606 13.8459 6.10352e-05 14.3785 6.10352e-05 14.9998Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M4.43803 25.6507C4.88182 26.0945 5.59188 26.0945 6.03567 25.6507C6.47945 25.2069 6.47945 24.4968 6.03567 24.0531C5.59188 23.6093 4.88182 23.6093 4.43803 24.0531C3.99425 24.4968 3.99425 25.2069 4.43803 25.6507Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M15.0001 29.9996C15.6214 29.9996 16.1539 29.467 16.1539 28.8457C16.1539 28.2244 15.6214 27.6919 15.0001 27.6919C14.3788 27.6919 13.8463 28.2244 13.8463 28.8457C13.8463 29.5558 14.3788 29.9996 15.0001 29.9996Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M25.6511 4.43758C25.2073 3.99379 24.4972 3.99379 24.0534 4.43758C23.6096 4.88136 23.6096 5.59142 24.0534 6.03521C24.4972 6.479 25.2073 6.479 25.6511 6.03521C26.0948 5.59142 26.0948 4.79261 25.6511 4.43758Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M14.3788 10.0294C14.5564 10.0294 14.8226 10.0294 15.0001 10.0294C15.1777 10.0294 15.4439 10.0294 15.6214 10.0294C15.6214 6.30159 17.3078 3.01757 19.9706 0.887391C19.5268 0.709876 18.9942 0.621118 18.5504 0.443604C16.9528 1.95248 15.7102 3.90514 15.0001 6.03532C14.2901 3.90514 13.0475 1.95248 11.4498 0.443604C11.0061 0.532361 10.4735 0.709876 10.0297 0.887391C12.6924 3.01757 14.3788 6.30159 14.3788 10.0294Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M18.1064 11.0059C18.3727 11.2721 18.7277 11.6272 18.994 11.8934C21.568 9.31947 25.1183 8.16562 28.5798 8.43189C28.4023 7.98811 28.136 7.54432 27.8698 7.10053C25.6508 7.01177 23.4319 7.54432 21.3905 8.52065C22.4556 6.47923 22.8993 4.26029 22.8106 2.04136C22.3668 1.77509 21.923 1.50881 21.4792 1.3313C21.8343 4.8816 20.6804 8.43189 18.1064 11.0059Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M18.994 18.1062C18.7277 18.3725 18.3727 18.7275 18.1064 18.9938C20.6804 21.5677 21.8343 25.118 21.568 28.5796C22.0118 28.4021 22.4556 28.1358 22.8993 27.8695C22.9881 25.6506 22.4556 23.4316 21.4792 21.3902C23.5206 22.4553 25.7396 22.8991 27.9585 22.8103C28.2248 22.3666 28.4911 21.9228 28.6686 21.479C25.1183 21.834 21.568 20.6802 18.994 18.1062Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M11.8937 18.9938C11.6274 18.7275 11.2724 18.3725 11.0061 18.1062C8.43215 20.6802 4.88186 21.834 1.42032 21.5677C1.59783 22.0115 1.86411 22.4553 2.13038 22.8991C4.34931 22.9879 6.56825 22.4553 8.60967 21.479C7.54458 23.5204 7.10079 25.7393 7.18955 27.9583C7.63334 28.2245 8.07713 28.4908 8.52091 28.6683C8.16588 25.118 9.31973 21.5677 11.8937 18.9938Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M24.0534 15C26.1836 14.2899 28.1363 13.0473 29.6451 11.4497C29.5564 11.0059 29.3789 10.4733 29.2013 10.0295C26.9824 12.6923 23.6984 14.3787 20.0593 14.3787C20.0593 14.5562 20.0593 14.8224 20.0593 15C20.0593 15.1775 20.0593 15.4437 20.0593 15.6213C23.7871 15.6213 27.0712 17.3076 29.2013 19.9704C29.3789 19.5266 29.4676 18.994 29.6451 18.5503C28.1363 16.9526 26.1836 15.71 24.0534 15Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M15.6214 20.059C15.6214 19.9702 15.6214 19.9702 15.6214 20.059C15.4439 20.059 15.1777 20.059 15.0001 20.059C14.8226 20.059 14.5564 20.059 14.3788 20.059C14.3788 23.7868 12.6924 27.0708 10.0297 29.201C10.4735 29.3785 11.0061 29.4673 11.4498 29.6448C13.0475 28.1359 14.2901 26.1833 15.0001 24.0531C15.7102 26.1833 16.9528 28.1359 18.5504 29.6448C18.9942 29.556 19.5268 29.3785 19.9706 29.201C17.3078 26.9821 15.6214 23.6981 15.6214 20.059Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M10.0297 15.6213C10.0297 15.4437 10.0297 15.1775 10.0297 15C10.0297 14.8224 10.0297 14.5562 10.0297 14.3787C6.30187 14.3787 3.01784 12.6923 0.887665 10.0295C0.71015 10.4733 0.621393 11.0059 0.443878 11.4497C1.95275 13.0473 3.90542 14.2899 6.0356 15C3.90542 15.71 1.95275 16.9526 0.443878 18.5503C0.532636 18.994 0.71015 19.5266 0.887665 19.9704C3.01784 17.3076 6.30187 15.6213 10.0297 15.6213Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
    <path
      d="M11.006 11.8934C11.2723 11.6271 11.6273 11.2721 11.8936 11.0058C9.31959 8.43187 8.16575 4.88157 8.52078 1.50879C8.07699 1.6863 7.6332 1.95258 7.18942 2.21885C7.10066 4.43778 7.6332 6.65672 8.60954 8.60938C6.65687 7.63305 4.43794 7.10051 2.219 7.18926C1.95273 7.63305 1.68646 8.07684 1.50894 8.52063C4.88172 8.1656 8.43202 9.31944 11.006 11.8934Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.projects_gallery.main
      )}
    />
  </svg>
);
export default MethodsAndDocumentationIcon;
