import React from 'react';

const LogoHighlight = () => {
  return (
    <svg
      width="191"
      height="57"
      viewBox="0 0 191 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3260_7045)">
        <path
          d="M36.157 11.437L32.5413 20.2913C31.9085 19.9224 31.1854 19.5535 30.3718 19.369L33.9875 10.5146L36.157 11.437Z"
          fill="#FFC20E"
        />
        <path
          d="M44.654 20.1069L35.9763 23.7963C36.3379 24.4419 36.6995 25.1797 36.8802 26.0098L45.558 22.3205L44.654 20.1069Z"
          fill="#FFC20E"
        />
        <path
          d="M11.2086 20.1069L10.3047 22.3205L18.9824 26.0098C19.1632 25.272 19.5248 24.5341 19.8863 23.7963L11.2086 20.1069Z"
          fill="#FFC20E"
        />
        <path
          d="M11.2086 36.8934L19.8863 33.2041C19.5248 32.5584 19.1632 31.8206 18.9824 30.9905L10.3047 34.6798L11.2086 36.8934Z"
          fill="#FFC20E"
        />
        <path
          d="M33.9875 46.4854L36.157 45.5631L32.5413 36.7087C31.9085 37.0777 31.1854 37.4466 30.3718 37.6311L33.9875 46.4854Z"
          fill="#FFC20E"
        />
        <path
          d="M44.654 36.8934L45.558 34.6798L36.8802 30.9905C36.6995 31.7283 36.3379 32.4662 35.9763 33.2041L44.654 36.8934Z"
          fill="#FFC20E"
        />
        <path
          d="M21.875 10.5146L19.7056 11.437L23.3213 20.2913C23.954 19.9224 24.6772 19.5535 25.4907 19.369L21.875 10.5146Z"
          fill="#FFC20E"
        />
        <path
          d="M21.875 46.4854L25.4907 37.6311C24.7676 37.4466 24.0444 37.0777 23.3213 36.7087L19.7056 45.5631L21.875 46.4854Z"
          fill="#FFC20E"
        />
        <path
          d="M26.7564 18.9999C26.7564 18.9999 26.7564 19.0922 26.7564 18.9999C27.118 18.9999 27.5699 18.9999 27.9315 18.9999C28.2931 18.9999 28.745 18.9999 29.1066 19.0922V18.9999C29.1066 11.9902 32.2703 5.71838 37.1516 1.56789C36.2476 1.29119 35.4341 1.01449 34.5302 0.737793C31.5472 3.59702 29.2874 7.1941 27.9315 11.3446C26.666 7.28634 24.3158 3.59702 21.3328 0.737793C20.4289 0.922259 19.525 1.19896 18.7114 1.56789C23.5926 5.71838 26.7564 11.9902 26.7564 18.9999Z"
          fill="#FFC20E"
        />
        <path
          d="M33.7165 20.9367C33.6261 20.9367 33.6261 20.9367 33.7165 20.9367C34.2588 21.4901 34.8012 22.0435 35.3435 22.6891C35.3435 22.6891 35.3435 22.6891 35.4339 22.5969C40.3152 17.6163 46.8234 15.495 53.1509 16.0483C52.7894 15.2183 52.3374 14.3882 51.795 13.5581C47.7274 13.4658 43.5693 14.2959 39.7728 16.325C41.671 12.5435 42.575 8.30077 42.4846 4.05806C41.671 3.59689 40.8575 3.13573 40.044 2.67456C40.6767 9.31534 38.5073 15.9561 33.7165 20.9367Z"
          fill="#FFC20E"
        />
        <path
          d="M35.3437 34.4027C35.3437 34.3104 35.2533 34.3104 35.3437 34.4027C34.8013 34.9561 34.259 35.5095 33.6262 36.0629C33.6262 36.0629 33.6262 36.0629 33.7166 36.1551C38.5978 41.1357 40.6769 47.7765 40.1345 54.2328C40.948 53.8638 41.7616 53.4027 42.5751 52.8493C42.6655 48.6988 41.852 44.4561 39.8633 40.5823C43.5694 42.5192 47.7275 43.4415 51.8856 43.3493C52.3375 42.5192 52.7895 41.6891 53.2415 40.859C46.7332 41.5046 40.2249 39.291 35.3437 34.4027Z"
          fill="#FFC20E"
        />
        <path
          d="M22.1464 36.063C22.1464 36.063 22.2368 35.9707 22.1464 36.063C21.604 35.5096 21.0617 34.9562 20.5193 34.3105C20.5193 34.3105 20.5193 34.3105 20.4289 34.4028C15.5477 39.3834 9.03941 41.5047 2.71191 40.9513C3.16388 41.6892 3.61584 42.5193 4.06781 43.3494C8.13548 43.4416 12.2936 42.6115 16.09 40.5824C14.1918 44.3639 13.2879 48.6067 13.3783 52.8494C14.1918 53.3105 15.0053 53.7717 15.8189 54.2329C15.1861 47.6843 17.3555 41.0436 22.1464 36.063Z"
          fill="#FFC20E"
        />
        <path
          d="M44.654 28.5001C48.6313 27.2088 52.247 24.8107 55.0492 21.767C54.8684 20.8447 54.5972 19.9224 54.2357 19.0923C50.168 24.0729 44.0213 27.301 37.1514 27.301H37.061C37.1514 27.67 37.1514 28.1311 37.1514 28.5001C37.1514 28.869 37.1514 29.3301 37.061 29.6991H37.1514C44.0213 29.6991 50.168 32.9272 54.2357 37.9078C54.5068 36.9855 54.778 36.1554 55.0492 35.2331C52.247 32.0971 48.7217 29.7913 44.654 28.5001Z"
          fill="#FFC20E"
        />
        <path
          d="M29.1066 37.9999C29.1066 37.9999 29.1066 37.9077 29.1066 37.9999C28.745 37.9999 28.2931 37.9999 27.9315 37.9999C27.5699 37.9999 27.118 37.9999 26.7564 37.9077V37.9999C26.7564 45.0097 23.5926 51.2815 18.7114 55.432C19.6154 55.7087 20.4289 55.9854 21.3328 56.2621C24.3158 53.4029 26.5756 49.8058 27.9315 45.6553C29.197 49.7135 31.5472 53.4029 34.5302 56.2621C35.4341 56.0776 36.338 55.8009 37.1516 55.432C32.18 51.2815 29.1066 45.0097 29.1066 37.9999Z"
          fill="#FFC20E"
        />
        <path
          d="M18.6209 29.6991C18.6209 29.6991 18.7113 29.6991 18.6209 29.6991C18.6209 29.3301 18.6209 28.869 18.6209 28.5001C18.6209 28.1311 18.6209 27.67 18.7113 27.301H18.6209C11.7511 27.301 5.60436 24.0729 1.53668 19.0923C1.2655 20.0146 0.994323 20.8447 0.723145 21.767C3.52532 24.8107 7.05064 27.1166 11.1183 28.5001C7.14103 29.7913 3.52532 32.1894 0.723145 35.2331C0.90393 36.1554 1.17511 37.0777 1.53668 37.9078C5.60436 32.835 11.7511 29.6991 18.6209 29.6991Z"
          fill="#FFC20E"
        />
        <path
          d="M20.5191 22.5972C20.5191 22.5972 20.5191 22.6894 20.5191 22.5972C21.0615 22.0438 21.6038 21.4904 22.2366 20.937C22.2366 20.937 22.2366 20.937 22.1462 20.8448C17.265 15.8642 15.1859 9.2234 15.7283 2.76709C14.9148 3.13602 14.1012 3.59719 13.2877 4.15058C13.1973 8.30107 14.0108 12.5438 15.9995 16.4176C12.2934 14.4807 8.1353 13.5584 3.97723 13.6506C3.52527 14.4807 3.0733 15.3108 2.62134 16.1409C9.12962 15.4952 15.6379 17.6166 20.5191 22.5972Z"
          fill="#FFC20E"
        />
        <path
          d="M27.9313 0C26.7562 0 25.8523 0.92233 25.8523 2.12136C25.8523 3.32039 26.7562 4.24272 27.9313 4.24272C29.1064 4.24272 30.0104 3.32039 30.0104 2.12136C30.0104 0.92233 29.1064 0 27.9313 0Z"
          fill="#FFC20E"
        />
        <path
          d="M8.1353 8.30104C7.32176 9.13114 7.32176 10.5146 8.1353 11.3447C8.94883 12.1748 10.3047 12.1748 11.1183 11.3447C11.9318 10.5146 11.9318 9.13114 11.1183 8.30104C10.3047 7.47094 9.03923 7.47094 8.1353 8.30104Z"
          fill="#FFC20E"
        />
        <path
          d="M0 28.5C0 29.6991 0.903928 30.6214 2.07903 30.6214C3.25414 30.6214 4.15807 29.6991 4.15807 28.5C4.15807 27.301 3.25414 26.3787 2.07903 26.3787C0.903928 26.3787 0 27.301 0 28.5Z"
          fill="#FFC20E"
        />
        <path
          d="M8.1353 48.6067C8.94883 49.4368 10.3047 49.4368 11.1183 48.6067C11.9318 47.7766 11.9318 46.3931 11.1183 45.563C10.3047 44.7329 8.94883 44.7329 8.1353 45.563C7.32176 46.4853 7.32176 47.7766 8.1353 48.6067Z"
          fill="#FFC20E"
        />
        <path
          d="M27.9313 57C29.1064 57 30.0104 56.0777 30.0104 54.8787C30.0104 53.6797 29.1064 52.7573 27.9313 52.7573C26.7562 52.7573 25.8523 53.6797 25.8523 54.8787C25.8523 56.0777 26.7562 57 27.9313 57Z"
          fill="#FFC20E"
        />
        <path
          d="M47.6371 48.6067C48.4506 47.7766 48.4506 46.3931 47.6371 45.563C46.8235 44.7329 45.4676 44.7329 44.6541 45.563C43.8406 46.3931 43.8406 47.7766 44.6541 48.6067C45.558 49.4368 46.8235 49.4368 47.6371 48.6067Z"
          fill="#FFC20E"
        />
        <path
          d="M55.8629 28.5C55.8629 27.301 54.959 26.3787 53.7839 26.3787C52.6088 26.3787 51.7048 27.301 51.7048 28.5C51.7048 29.6991 52.6088 30.6214 53.7839 30.6214C54.959 30.6214 55.8629 29.6991 55.8629 28.5Z"
          fill="#FFC20E"
        />
        <path
          d="M47.6371 8.30104C46.8235 7.47094 45.4676 7.47094 44.6541 8.30104C43.8406 9.13114 43.8406 10.5146 44.6541 11.3447C45.4676 12.1748 46.8235 12.1748 47.6371 11.3447C48.4506 10.5146 48.4506 9.22337 47.6371 8.30104Z"
          fill="#FFC20E"
        />
        <path
          d="M74.303 10.5146H82.4383C93.2855 10.5146 98.4379 17.5244 98.4379 28.4079C98.4379 39.2914 93.2855 46.5778 82.5287 46.5778H74.303V10.5146ZM82.1672 44.3642C91.6584 44.3642 95.8165 37.7234 95.8165 28.4079C95.8165 19.0001 91.6584 12.636 82.1672 12.636H76.7436V44.3642H82.1672Z"
          fill="#FFC20E"
        />
        <path
          d="M116.155 10.5146H118.686L131.07 46.4855H128.358L121.307 25.2719C119.861 21.0292 118.595 17.2477 117.42 12.8205H117.24C115.974 17.2477 114.799 21.0292 113.353 25.2719L106.212 46.4855H103.681L116.155 10.5146Z"
          fill="#FFC20E"
        />
        <path
          d="M146.165 12.636H133.872V10.4224H160.899V12.636H148.606V46.4855H146.165V12.636Z"
          fill="#FFC20E"
        />
        <path
          d="M176.085 10.5146H178.616L191 46.5778H188.379L181.328 25.3642C179.882 21.1214 178.616 17.3399 177.441 12.9127H177.261C175.995 17.3399 174.82 21.1214 173.374 25.3642L166.233 46.5778H163.702L176.085 10.5146Z"
          fill="#FFC20E"
        />
      </g>
      <defs>
        <clipPath id="clip0_3260_7045">
          <rect width="191" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoHighlight;
